
#menuSider .v4h-logo-menu {
    max-width: 60px;
    padding: 0 10px 0 0;
    cursor: pointer;
}

#menuSider button {
    border: none;
    background-color: transparent;
    width: 40px;
    position: absolute;
    left: 24px;
}

#menuSider button:hover {
    transform: scale(1.2, 1.2);
    transition: all 0.4s;
}

#menuHorizontal button {
    border: none;
    background-color: transparent;
    width: 40px;
}

#menuHorizontal .v4h-logo-menu {
    cursor: default;
    width: 40px;
}

.ant-menu-item-selected {
  color: var(--turquoise) !important;
  border-color: red;
}
 
.ant-menu > .ant-menu-item:hover {
  color: var(--turquoise) !important;
}