#bodyMobile {
    padding-top: 50px;
    background: radial-gradient(ellipse at bottom, #292D61 30%, #181D41 80%);
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    min-width: 100vw;
}

.containerMobile {
    max-width: 85%;
}

#bodyMobile * {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white !important;
}

#bodyMobile h1 {
    color: white !important;
    font-size: 30px;
}

#bodyMobile p {
    font-size: 18px;
}

#bodyMobile a {
    font-size: var(--buttonFontSize);
    padding: 10px 15px;
    margin: 10px;
    background-color: var(--buttonColor);
    border-radius: 5px;
}

#bodyMobile a:hover {
    background-color: var(--buttonColorHover);
}

#bodyMobile .tutorialMobile {
    margin-top: 30px;
}

#bodyMobile .videoMobile {
    margin: 20px;
    width: 90%;
    height: 90%;
    max-width: 600px;
    max-height: 600px;
}

#bodyMobile .footerMobile {
    margin-top: 40px;
    background-color: #080614;
    width: 100%;
}

#bodyMobile img {
    max-width: 300px;
    width: 30%;
}

#bodyMobile #LinkArea {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
}