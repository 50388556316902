.quickConference-container {
    height: 100%;
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--backgroundAwaitingConf);
}

.buttonInvite {
    position: absolute;
    bottom: 50%;
    left: 2%;
    height: 5%;
    width: 5%;
    border-radius: 50%;
    background-color: transparent;
    color: white;
    font-size: large;
}

#meetMenu {
    width: 100%;
}

#meet {
    width: 100%;
    height: 100%;
}

#spin {
    width: 100%;
    height: 100%;
    display:  flex;
    justify-content: center;
    align-items: center;
}

