.singin-container {
    height: 100%;
    display:  flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    flex-direction: column;
}

.singin-container .singin-form {
    width: 450px;
    height: 400px;
    border: 2px solid #dbdada;
    border-radius: 10px;
}

.singin-container .singin-form form {
    width: 100%;
    display:  flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0px;
}

.singin-container .singin-form .container-img img {
    width: 50px;
}

.singin-container .singin-form form h2 {
    margin: 0px;
    padding: 0px;
}

.singin-container .singin-form .user_email {
    font-size: 15px;
    color: rgb(94, 94, 94);
    border: 1px solid rgb(94, 94, 94);
    border-radius: 30px;
    padding: 0px 10px;
    position: relative;
}

.singin-container .singin-form form p {
    margin-top: 20px;
}

.singin-container .singin-form form input {
    border: none;
    border: 2px solid rgb(94, 94, 94);
    border-radius: 10px;
    width: 80%;
    padding: 10px;
    font-size: 18px;
}

.singin-container .singin-form form button {
    width: 100px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--turquoise);
    color: #fff;
    margin-top: 40px;
}

.singin-container .singin-form form button:hover {
    background-color: var(--dark-turquoise);
    cursor: pointer;
}

.error-form {
    border: 2px solid red !important;
}

.singin-container .singin-form form .error-msg {
    color: red;
    visibility: hidden;
}

.error-msg-visible {
    visibility: visible !important;
}