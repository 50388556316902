/****************************/
/* settings for this button */
/****************************/
.menuBackButton .backButton{
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 22px;
}

.menuBackButton .backButton button {
    background: transparent;
    border: none;
    cursor: pointer;
}
/****************************/
/* settings for this button */
/****************************/

/***********************************/
/* settings for conferenceSettings */
/***********************************/
.conferenceSettings-container .backButton button {
    color: white;
}

/***********************************/
/* settings for conferenceSettings */
/***********************************/