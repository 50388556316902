.range {
  margin-left: 5px;
  width: 220px;
  font-size: 14px;
  color: rgba(0,0,0,.65);
}

.range .ant-input {
  background-color: #f0f0f0;
  border-radius: 0 3px 3px 0;
  font-weight: 700;
  border: 1px solid #dfdfdf;
  font-size: 14px;
}

.range .ant-calendar-range-picker-input {
  font-weight: bold;
}

.range .ant-calendar-range-picker-separator {
  vertical-align: baseline;
  color: rgba(0,0,0,.65);
}

.range.ant-calendar-picker.ant-calendar-picker-large > span {
  border: 1px solid #dfdfdf;
  box-shadow: none;
}

#rangePagination .range.ant-calendar-picker.ant-calendar-picker-large > span {
  border: 1px solid #dfdfdf;
  box-shadow: none;
}