* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

:root {
  --background-form: rgba(0, 0, 0, 0.438);
  --purple: #000000d7;
  --purple-1: #4700b8;
  --withe: #f5f5f5;
  --black: #080808;
  --turquoise: #52c8fa;
  --dark-turquoise: #0098da;
  --gray: #999;
  --light: #f5f5f5;

  /* Buttons */
  --buttonFontSize: 18px;
  --buttonFontColor: #000;
  --buttonColor: #52c8fa;
  --buttonColorHover: #0098da;

  /* screen awaiting videoconference */
  --backgroundAwaitingConf: rgb(236, 236, 236);
}

html,
body,
#root {
  height: 100%;
}

body {
  background: var(--withe);
}

body,
input,
button {
  font-family: Arial, Helvetica, sans-serif;
}

/* Cor de fundo do autocomplete */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px transparent inset;
}
