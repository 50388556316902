.appbarcontainer {
    background-color: white;
    height: 67px;
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
    padding: 15px 10px;
}

.appbarcontainer h1{
    text-align: left;
}