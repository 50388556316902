.formRegisterUser .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    margin: 10px auto;
    border: 2px solid var(--withe);
    border-radius: 10px;
}

.formRegisterUser .registerButton {
    width: 100%;
}

.formRegisterUser input {
    padding-left: 10px;
    width: 85%;
    background-color: transparent;
    border: none;
    color: var(--withe);
}

.formRegisterUser select {
    padding-left: 10px;
    width: 85%;
    height: 100%;
    background-color: transparent;
    border: none;
    color: var(--withe);
}

.formRegisterUser select option {
    color: black;
}

.formRegisterUser input::placeholder {
    color: var(--withe);
}

.registerUserInputError {
    color: red !important;
    border: 2px solid red !important;
}

.registerUserIconError {
    color: red !important;
}