.sessionContainer {
    height: 100%;
    width: 100%;
}

.sessionBody{
    background: #ffffff;
    /*margin: 20px 20px;*/
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    height: 100%;
    width: 100%;
}

.appbar {
    width: 100%;
}

.sessions {
    
    background-color: white;
    height: calc(100vh - 70px);
}

.sessionList {
    /*background-color: white;*/
    height: 100%;
    border: 0px solid #e8e8e8;
    width: 80%;
    max-width: 920px;
    padding-top: 15px;
}

.sessionBody .sessionItens {
    height: calc(100% - 60px);
    padding: 5px;
}

.filterCont {
    border-bottom: 1px solid #e8e8e8;
    height: 60px;
    padding: 10px;
}

.filter {
    padding: 5px;
    height: 50px;
    position: relative;
    top: 4px;
    display: flex;
    padding: 10px;
    float: left;
    z-index: 1;
}

.iconFilter:hover {
    transform: scale(1.5, 1.5);
    transition: all 0.4s;
}

#root > section > div.sessionBody > div.ant-row.sessions {
    height: calc(100% - 67px);
    display: flex;
    justify-content: center;
}

#root > section > div.sessionBody > div.ant-row.sessions > div > div.sessionItens > div > div > div > div > div > ul > li {
    border: 0px;
    padding: 3px 0px;
}

.sessionButton {
    display: flex;
    justify-content: space-between;
    height: 40px;
    color: rgba(0, 0, 0, 0.65);
  }
  
  .leftButtonContainer {
    display: flex;
    width: 400px;
    height: 100%;
  }
  
  .pagButton {
    background:none;
    border: 0px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    background-color: rgb(244,244,244);
    border: 1px solid #DFDFDF;
  }
  
  .pagButton:hover {
    background-color: #f7f7f7;
  }
  
  .iconButton {
    font-size: 23px;
  }
  
  .pagInfo {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: bold;
    background-color: rgb(244,244,244);
    border: 1px solid #DFDFDF;
    border-radius: 0px 3px 3px 0px;
    padding: 10px 5px 0px 7px;
    width: 250px;
  }

  .pagInfo .trace {
    font-size: 12px;
    position: relative;
    bottom: 3%;
  }

  .pagInfo .pagination {
    font-size: 11px;
    position: relative;
    bottom: 3%;
  }
  
  .filterButton {
    background: none;
    border: 0px;
    border-radius: 3px;
    padding-left: 3px;
    cursor: pointer;
    width: 80px;
    margin-right: 10px;
    background-color: rgb(244,244,244);
    border: 1px solid #DFDFDF;
  }
  
  .filterButton:hover {
    background-color: #f7f7f7;
  }
  
  .filterButton > i{
    font-size: 17px;
    padding-right: 5px;
  }
  
  .filterButton > span{
    position: relative;
    top: 25%;
    transform: translateY(-25%);
    padding-left: 2px;
    font-weight: bold;
  
  }
  
  .rightButtonContainer {
    display: flex;
    justify-content: flex-end;
    width: 150px;
    height: 100%;
  }

  