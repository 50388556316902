.changePassword-container {
    height: 100%;
    display:  flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    flex-direction: column;
}

.changePassword-container .menu .backButton button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 22px;
}

.changePassword-container .changePassword-form {
    display: flex;
    justify-content: center;
    width: 450px;
    height: 350px;
    border: 2px solid #dbdada;
    border-radius: 10px;
}

.changePassword-container .changePassword-form form {
    display: block;
    width: 80%;
    margin: 20px;
}

.changePassword-container .changePassword-form form input {
    width: 100%;
    border: none;
    border: 2px solid rgb(94, 94, 94);
    border-radius: 10px;
    padding: 10px;
    font-size: 18px;
}

.changePassword-container .changePassword-form form button {
    background-color: var(--turquoise);
    width: 100px;
    margin-top: 40px;
    padding: 10px;
    color: #fff;
    border: none;
    border-radius: 5px;
    float: right;
}

.changePassword-container .changePassword-form form button:hover {
    background-color: var(--dark-turquoise);
    cursor: pointer;
}

.error-form {
    border: 2px solid red !important;
}

.changePassword-container .changePassword-form form .error-msg {
    color: red;
    visibility: hidden;
}

.error-msg-visible {
    visibility: visible !important;
}