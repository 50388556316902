.ScheduleAppointment-body {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0px 0px;
    text-align: center;
    background-color: white;
    overflow: hidden;
}

/*remove border calendario
.activities-list {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
}*/

.headercalendar {
    width: 100%;
    height: 60px;
    background-color: white;
}

.headercalendar .leftbutton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}

.headercalendar .middlebutton {
    justify-content: center;
    align-items: baseline;
    display: flex;
    height: 10%;
    padding: 10px 0px 10px 0px;
}

.headercalendar .rightbutton {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    height: 60px;
    padding: 10px 10px 10px 10px;
}

.activities-list .add {
    margin-top: 10px;
    margin-bottom: 4px;
    position: relative;
    float: left;
    z-index: 1;
}

.activitiesDay {
    height: 100%;
}

.activitiesDay .dayInfo {
    height: 29px;
    font-size: 16px;
    padding-top: 5px;
}

.schedule {
    display: flex;
    justify-content: center;
    margin: 0px;
}

.ant-badge-status .ant-badge-status-dot {
    position: relative;
    top: -11px;
    display: inline-block;
    width: 14px;
    height: 2px;
    vertical-align: middle;
    border-radius: 0%;
}

.ant-badge-status .ant-badge-status-text {
    margin-left: 0px;
}

.ant-layout {
    height: 100%;
}

#schendule .ant-fullcalendar-date {
    --heigthRow: calc(100vh - 405px);
    --heightDiv: calc(var(--heigthRow) / 6);
    --heigth: calc(var(--heightDiv) + 40px);
    height: var(--heigth);
    padding: 4px 2px 4px 2px;
}

#schendule .ant-fullcalendar-content {
    height: calc(var(--heigth) - 35px);
    overflow: auto;
}

#schendule .eventsCalendar {
    margin-bottom: 0px;
}

#schendule .eventsCalendar .listItemCalendar {
    height: 20px;
    margin: 2px 1px;
}

#schendule .eventsCalendar .listItemCalendar .content {
    display: inline-flex;
    width: 100%;
    background-color: #f7f9f9;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px;
    color: rgba(128, 128, 128, 0.5);
    font-size: 12px;
    border-radius: 3px;
    padding: 2px;
}

#schendule .eventsCalendar .line {
    background-color: var(--turquoise);
    height: 80%;
    width: 5px;
    border-radius: 4px;
}

#schendule .eventsCalendar span {
    padding-left: 4px;
}

#schendule .eventsCalendar .listItemCalendar .content:hover {
    background-color: rgb(236, 236, 236);
}

#schendule .ant-fullcalendar-content::-webkit-scrollbar {
    width: 2px;
}

#schendule .ant-fullcalendar-content::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 0px;
}

#schendule .ant-fullcalendar-content::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.5);
    border: 0px none #ffffff;
    border-radius: 0px;
}

#schendule>div>div>div.ant-fullcalendar.ant-fullcalendar-full.ant-fullcalendar-fullscreen>div>table>tbody>tr:nth-child(3)>td:nth-child(4)>div::-webkit-scrollbar {
    background-color: black;
}

.ScheduleAppointment-body .demo-infinite-container {
    border: 1px solid #e8e8e8;
    background-color: #f9f9f9;
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: calc(100%);
}

.ScheduleAppointment-body .demo-loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
}

.ScheduleAppointment-body .listItem {
    display: flex;
    background-color: white;
    /*box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.25);*/
    cursor: pointer;
    height: 70px;
    width: 100%;
}

.ScheduleAppointment-body .listItem:hover {
    /*box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.5);*/
    background-color: rgb(236, 236, 236);
}

.ScheduleAppointment-body .listItem .ScheduleListButton-container:hover {
  /*box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.5);*/
  background-color: rgb(255, 255, 255);
}


.ScheduleAppointment-body .listItem .lineContent {
    height: 100%;
    width: 15px;
    padding: 15px 5px;
}

.ScheduleAppointment-body .listItem .line {
    background-color: var(--turquoise);
    height: 100%;
    width: 7px;
    border-radius: 3px;
}

.ScheduleAppointment-body .listItem .time {
    display: flex;
    align-items: center;
    height: 100%;
    width: 50px;
    padding-left: 0px;
}

.ScheduleAppointment-body .listItem .title-host {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    padding: 15px;
}

.ScheduleAppointment-body .listItem .title-host h4 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-align: left;
  max-height: 20px;
  margin: 0px;
}

.ScheduleAppointment-body .listItem .title-host .host {
    text-align: left;
    font-size: 12px;
}

#root>section>div.ScheduleAppointment-body>div.ant-row.schedulecontainer {
    height: calc(100% - 131px);
}

#root>section>div.ScheduleAppointment-body>div.ant-row.schedulecontainer>div.ant-col.ant-col-8.activitiesDay>div.demo-infinite-container>div>div>div>div>ul>li {
    border: 0px;
}

.ScheduleAppointment-body .prevButton {
    border: 0px;
    border-radius: 10px 0px 0px 10px;
    background-color: #f7f9f9;
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.ScheduleAppointment-body .prevButton:hover {
    background-color: rgb(236, 236, 236);
}

.ScheduleAppointment-body .nextButton {
    border: 0px;
    border-radius: 0px 10px 10px 0px;
    background-color: #f7f9f9;
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.ScheduleAppointment-body .nextButton:hover {
    background-color: rgb(236, 236, 236);
}

.ScheduleAppointment-body .todayButton {
    border: 0px;
    border-radius: 10px;
    background-color: #f7f9f9;
    cursor: pointer;
    width: 80px;
    height: 40px;
    margin-right: 15px;
}

.ScheduleAppointment-body .todayButton:hover {
    background-color: rgb(236, 236, 236);
}

.ScheduleAppointment-body .leftbutton .todaySpan {
    position: relative;
    font-weight: bold;
    /*left: 50%;*/
    top: 0%;
    align-self: center;
    font-size: 18px;
}

.ScheduleAppointment-body .leftbutton span {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    top: 50%;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

.ScheduleAppointment-body .monthButton {
    border: 0px;
    border-radius: 10px 0px 0px 10px;
    background-color: #f7f9f9;
    cursor: pointer;
    width: 60px;
    height: 40px;
}

.ScheduleAppointment-body .monthButton:hover {
    background-color: rgb(236, 236, 236);
}

.ScheduleAppointment-body .monthButton span {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    top: 50%;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

.ScheduleAppointment-body .yearButton {
    border: 0px;
    border-radius: 0px 10px 10px 0px;
    background-color: #f7f9f9;
    cursor: pointer;
    width: 60px;
    height: 40px;
}

.ScheduleAppointment-body .yearButton:hover {
    background-color: rgb(236, 236, 236);
}

.ScheduleAppointment-body .yearButton span {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    top: 50%;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}