#headerFilterContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 920px;
}

#headerFilterContainer .leftButtons {
  margin-left: 73px;
}

.rightButtons {
  display: flex;
}

.searchButton {
  height: 100%;
  background-color: #f0f0f0;
  border: 1px solid #dfdfdf;
  width: 40px;
  font-size: 17px;
}

.ant-calendar-footer-extra {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  margin: 10px 0px;
}

.btnClearFilter {
  background: none;
  color: var(--dark-turquoise);
  border: 2px solid var(--dark-turquoise);
  border-radius: 5px;
  padding:  0px 10px;
}

.btnClearFilter:hover {
  color: var(--purple);
  border: 2px solid var(--purple);
  transition-duration: 0.5s;
}