
.filterButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px 0px 0px 3px;
  cursor: pointer;
  width: 80px;
  height: 100%;
  background-color: #f0f0f0;
  border: 1px solid #dfdfdf;
  font-weight: bold;
  font-size: 14px;
  margin-right: -1px;
}

.filterButton:hover {
  background-color: rgb(236, 236, 236);
}

.filterButton>i {
  padding-right: 5px;
}

.filterContainer {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 250px;
}

.filterContainer .filterId2 {
  display: flex;
}

.filterContainer .filterId2 input {
  width: 80%;
}

.filterContainer .filterInput {
  padding: 5px 0px;
}

.filterContainer .buttons {
  padding: 5px 0px;
  display: flex;
  justify-content: flex-end;
}

.filterContainer .buttons button{
  margin-left: 5px;
}

.closeIcon {
  text-align: end;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
}

.closeIcon:hover {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.4s;
}

.buttons .clear {
  font-size: smaller;
  font-weight: bold;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.65);
  border: none;
  box-shadow: none;
}

.buttons .clear:hover {
  background-color: rgb(236, 236, 236);
  color: rgba(0, 0, 0, 0.65);
}

.buttons .clear:focus {
  background-color: rgb(236, 236, 236);
  color: rgba(0, 0, 0, 0.65);
}

.buttons .search {
  font-size: smaller;
  font-weight: bold;
}