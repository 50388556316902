.container-NewQuickConference {
  width: 100vw;
  height: 100vh;
  background-color: #EFECEA;
  background-image: url("../../assets/v4h/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.container-input {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: black;
}

.input-container {
  position: relative;
  width: 500px;
  height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.confname-input {
  width: 350px;
  height: 50px;
  border-radius: 10px;
  padding-left: 10px;
  margin-bottom: 5px;
}

.submit-button {
  height: 50px;
  width: 350px;
  border: none;
  border-radius: 10px;
  background-color: var(--turquoise);
  transition-duration: 0.5s;
  color: white;
  cursor: pointer;
}

.submit-button:hover {
  background-color: var(--dark-turquoise);
}

.inputError {
  border: 2px solid red !important;
}
