.perservationItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.perservationItemContainer .perservationButton button {
    box-shadow: rgba(23, 43, 99, 0.24) 0 7px 28px !important;
    border-radius: 2px;
    background-color: rgb(233, 127, 194);
    border: 1px solid rgb(233, 127, 194);
    height: 35px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    font-family: 'Open Sans', monospace;
}

.perservationItemContainer .perservationButton button {
    color: white;
}

.perservationItemContainer .perservationButton button i {
    font-size: 18px;
    margin: 5px 10px;
}

.perservationItemContainer .perservationButton button:hover{
    transform: translateY(-5%);
    transition: all 0.4s;
}
.perservationItemContainer .disabled {
    display: none;
}