#containerForgetPassword {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#containerForgetPassword .title {
  font-size: 30px;
  font-weight: bold;
  color: black;
}

.container-img {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.container-img img{
  max-width: 200px;
}

#containerForgetPassword .description {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

#containerForgetPassword form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#containerForgetPassword input {
  width: 400px;
  border-radius: 5px;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 20px;
}

#containerForgetPassword .btnSubmit {
  background-color: var(--turquoise);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  padding: 10px 40px;
}
