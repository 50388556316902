#avatar {
    cursor: pointer;
}

#menuHorizontal #menuItenAvatar {
    display: none;
}

#menuHorizontal #avatar {
    border: 2px solid gray;
    position: absolute;
    top: 3px;
    right: 20px;
}

#menuSider #avatar {
    margin-left: 100px;
}

#menuSider #avatar {
    border: 2px solid gray;

}

#avatarConf {
    border: 2px solid gray;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
}

#AvatarMenu {
    text-align: center;
    width: 400px;
}

#AvatarMenu .header {
    margin-bottom: 30px;
}

#userName, #userEmail {
    margin: 0;
}