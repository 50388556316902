.ScheduleListButton-container {
  display: flex;
  padding: 6px 10px !important;
  margin: 0px !important;
  min-width: 25%;
  align-items: flex-end;
}

.ScheduleListButton-container button {
  border: none;
  background-color: transparent;
  margin: 5px;
  width: 150px;
  font-size: 100%;
}

#root
  > section
  > div.ScheduleAppointment-body
  > div.ant-row.schedulecontainer
  > div.ant-col.ant-col-8.activitiesDay
  > div.demo-infinite-container
  > div
  > div
  > div
  > div
  > ul
  > li
  > div
  > div.ScheduleListButton-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 10px 17px 10px;
  width: calc(30% - 55px);
  font-size: 20px;
}

.ScheduleListButton-container button:hover {
  transform: scale(1.5, 1.5);
  transition: all 0.4s;
}
