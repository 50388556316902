.main-container {
  height: 100%;
  width: 100%;
  /* display: flex; */
  /* flex-direction: row; */
}

.left-container {
  height: 100%;
  width: 40%;
  display: flex;
  /* background-image: url("../../assets/pbmeet/left.svg"); */
}

.right-container {
  height: 100%;
  width: 40%;
  display: flex;
  /* background-image: url("../../assets/pbmeet/right.svg");
  background-size: cover; */
}

.login-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  flex-direction: column;
  background-color: #EFECEA;
  background-image: url("../../assets/v4h/background-login.png"); 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* @media screen and (min-width: 1100px) {
  .login-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white);
    flex-direction: column;
    background-color: #EFECEA;
    background-image: url("../../assets/v4h/background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (min-width: 1100px) {
  .login-container .container-img-footer {
    display: none;
    justify-content: center;
    width: 100%;
    max-width: 500px;
  }
} */

.login-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.5;
  /* background-image: url("../../assets/pbmeet/tela.svg"); */
}

.login-container form {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.login-container .container-img {
  display: flex;
  justify-content: center;
}

.login-container img {
  max-width: 100px;
}

.login-container .container-img-footer {
  justify-content: center;
  width: 90%;
  max-width: 500px;
}

.container-img-footer img {
  width: 100%;
  max-width: 100%;
  margin-top: 100px;
}

.login-container form input {
  margin-top: 10px;
  height: 48px;
  background: var(--light);
  border: 2.5px solid var(--black);
  border-radius: 4px;
  padding: 0 20px;
  font-size: 16px;
  opacity: 0.8;
  color: var(--black);
}

.login-container form input::placeholder {
  color: var(--black);
}

.login-container form input:focus {
  color: var(--black);
  border: 2.5px solid var(--turquoise);
  -webkit-transition-property: border;
  -webkit-transition-duration: 1s;
}

.login-container form .forgetPassword {
  color: var(--black);
  padding-top: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 5px;
}

.login-container form .submit {
  margin-top: 10px;
  border: 0;
  border-radius: 4px;
  height: 48px;
  font-size: 20px;
  background-color: var(--turquoise);
  font-weight: bold;
  color: var(--withe);
  cursor: pointer;
  transition: background-color 0.5s;
}

.login-container form .submit:hover {
  background-color: var(--dark-turquoise);
}

.login-container form .submit:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

.registerButtonSvg,
.registerButtonRect {
  max-width: 200px;
  height: 40px;
  width: 100%;
  cursor: pointer;
}

.registerButtonContainer {
  position: relative;
  top: 30px;
  margin-top: 0 auto;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.registerButtonRect {
  stroke-dasharray: 140 540;
  stroke-dashoffset: -272;
  stroke-width: 5px;
  fill: transparent;
  stroke: var(--turquoise);
  border-bottom: 5px solid black;
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
}

.registerButtonText {
  /* font-family: "Roboto Condensed"; */
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 3px;
  color: var(--black);
  position: absolute;
  top: 3px;
  cursor: pointer;
}

.registerButtonContainer:hover .registerButtonRect {
  stroke-width: 2px;
  stroke-dashoffset: 0;
  stroke-dasharray: 760;
}
