#myAccount {
    height: 100%;
    display:  flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    flex-direction: column;
}

#myAccount .myAccountContainer {
    max-width: 900px;
    width: 80%;
    height: 80%;
}