.formRegisterCompany .container {
    max-width: 350px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    margin: 10px auto;
    border: 2px solid var(--withe);
    border-radius: 10px;
}

.formRegisterCompany .registerButton {
    width: 100%;
}

.formRegisterCompany input {
    padding-left: 10px;
    width: 85%;
    background-color: transparent;
    border: none;
    color: var(--withe);
}

.formRegisterCompany select {
    padding-left: 10px;
    width: 85%;
    height: 100%;
    background-color: transparent;
    border: none;
    color: var(--withe);
}

.formRegisterCompany input::placeholder {
    color: var(--withe);
}

.registerCompanyInputError {
    color: red !important;
    border: 2px solid red !important;
}

.registerCompanyIconError {
    color: red !important;
}