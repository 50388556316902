
#myAccountConfigContainer .header {
    text-align: center;
}

#myAccountConfigContainer .body {
    width: 100%;
}

tr.ant-table-row.ant-table-row-level-0 {
    margin-top: 100px !important;
}

th.margin, td.margin {
    font-size: 15px;
    margin-top: 100px !important;
}

.personInfoTitle {
    width: 100%;
    margin: 10px;
    color: gray;
}

.personInfoTitle .personInfoTitlePerfil {
    color: black;
    font-size: 20px;
}

.personInfoIcon {
    text-align: right;
}

#myAccountConfigContainer .body .password {
    background-color: transparent;
    border:none;
}