.registerContainer {
    height: 100%;
    display:  flex;
    justify-content: center;
    align-items: center;
    background: var(--purple);
    color: white;
}

.registerContainer::before {
    content:"";
    position:absolute;
    width:100%;
    height:100%;
    z-index:0;
    opacity:0.2;
    background-color: #EFECEA;
    background-image: url("../../assets/v4h/background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.backButtonSvg, .backButtonRect {
    max-width: 100px;
    height: 40px;
    width: 100%;
    cursor: pointer;
}

.backButtonContainer {
    position: absolute;
    top: 40px;
    left: 50px;
    margin-top: 0 auto;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
}

.backButtonRect {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -272;
    stroke-width: 5px;
    fill: transparent;
    stroke: var(--turquoise);
    transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
}

.backButtonText {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 3px;
    color: var(--withe);
    position: absolute;
    top: 3px;
    cursor: pointer;
}

.backButtonContainer:hover .backButtonRect {
    stroke-width: 2px;
    stroke-dashoffset: 0;
    stroke-dasharray: 760;
}

.registerForm-Container {
    z-index: 1;
    max-width: 400px;
    max-height: 900px;
    width: 100%;
    height: 100%;
    /*padding-top: 100px;*/
}

.registerForm {
    max-width: 400px;
    max-height: 900px;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: var(--background-form);
    border-radius: 20px;
}

.registerForm .registerFormHeader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.registerForm .registerFormHeader img {
    max-width: 140px;
    width: 100%;
}

.registerForm .registerFormBar {
    border-bottom: 2px solid var(--turquoise);
    max-width: 100px;
    width: 80%;
    margin: 20px auto;
}

.registerButton {
    margin: 20px auto;
    max-width: 300px;
    width: 80%;
}

.registerButton button {
    background-color: var(--turquoise);
    border: 2px solid black;
    border-radius: 10px;
    max-width: 350px;
    width: 100%;
    height: 40px;
    color: black;
    transition: background-color 0.5s;
}

.registerButton button:hover {
    background-color: var(--dark-turquoise)
}

.registerForm .registerFormFooter {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    color: var(--gray);
}

.registerForm .registerFormFooter .registerFormFooterLink {
    color: var(--turquoise);
    cursor: pointer;
}