#inputConfigModal {
  margin: 20px 10px 0px 10px;
  display: flex;
  flex-direction: column;
}

#inputconfigModal .form-label {
  color: rgb(255, 0, 0);
}

#inputConfigModal input {
  border: none;
  border-bottom: 2px solid gray;
  width: 100%;
  margin-bottom: 20px;
  color: black;
}

#inputConfigModal #inputConfigModal_email {
  margin-bottom: 0px;
}

#inputConfigModal input:focus {
  border: none;
  border-bottom: 2px solid var(--turquoise);
  width: 100%;
}

#inputConfigModal form button {
  background-color: var(--turquoise);
  padding: 5px 20px;
  margin: 20px 0px 0px 0px;
  border: none;
  border-radius: 5px;
  color: white;
  float: right;
  clear: both;
}

.form-error {
  border-bottom: 2px solid red !important;
}

#inputConfigModal .error-msg {
  color: red;
  visibility: hidden;
  padding-bottom: 100px;
}

.error-msg-visible {
  visibility: visible !important;
}

#inputConfigModal .btnDeleteAccount {
  background: #eb5757;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

#inputConfigModal .btnCancel {
  background: #d4d4d4;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}
