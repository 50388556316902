/******************/
/* root container */
/******************/
.conferenceSettings-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/******************/
/* root container */
/******************/

.nameConference {
    font-size: 38px;
    color: white;
    padding-top: 10px;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nameConference span{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.nameConference span.ellipsis::after {
  content: "...";
}


/***********************************/
/* Settings for buttons containers */
/***********************************/
.conferenceSettings-body .buttons .btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 2px solid white; 
    border-radius: 100%;
    margin: 0px 20px;
}

.conferenceSettings-body .buttons .btn-div:hover {
    border: 2px solid gray;
    background-color: rgba(0, 0, 0, 0.712);
    -webkit-transition-property: background-color, border;
    -webkit-transition-duration: 0.5s;
}
/***********************************/
/* Settings for buttons containers */
/***********************************/


/*****************************/
/* Settings for camera space */
/*****************************/
.conferenceSettings-body .conferenceSettings-cam {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 301px;
    background-color: black;
    border: 2px solid white;
    margin: 20px 0px;
}

.conferenceSettings-body .conferenceSettings-cam #webCamera {
    width: 100%;
    height: 100%;   
}

.conferenceSettings-body .conferenceSettings-cam #no-webCamera {
    color: white;
    font-size: 30px;
}
/*****************************/
/* Settings for camera space */
/*****************************/


/************************/
/* Settings for buttons */
/************************/
.conferenceSettings-body .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
}

.conferenceSettings-body .btn {
    width: 70%;
    height: 70%;
    color: white;
}

.conferenceSettings-body .btn_startConf {
    border: none;
    background-color: var(--buttonColor);
    color: var(--buttonFontColor);
    border-radius: 5px;
    padding: 10px 50px;
    margin: 0px 0px 10px 0px;
    font-size: var(--buttonFontSize);
}

.conferenceSettings-body .btn_back {
  border: none;
  background-color:white;
  color: #000000;
  border-radius: 5px;
  padding: 10px 50px;
  margin: 0px 10px 10px 0px;
  font-size: var(--buttonFontSize);
}

.conferenceSettings-body .btn_back:hover {
    background-color: #999;
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 0.5s;
}

.conferenceSettings-body .btn_startConf:hover {
  background-color: var(--buttonColorHover);
  -webkit-transition-property: background-color;
  -webkit-transition-duration: 0.5s;
}
/************************/
/* Settings for buttons */
/************************/

/***********/
/* outhers */
/***********/
.conferenceSettings-body .participants {
    color: white;
    margin-top: 0px;
    visibility: hidden;
}

.container-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.container-name input {
  border: none;
  border-bottom: 2px solid gray;
  width: 70%;
  height: 33px;
  background-color: white;
  color: black;
  font-size: 18px;
}

.container-name label {
  width: 70%;
  font-size: 18px;
  margin-top: 5px;
  justify-content: left;
  align-items:flex-start;
  color: white;
}