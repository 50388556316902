#containerDeleteAccount {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#containerDeleteAccount .card {
  border: 1px solid #b1b1b1;
}

#containerDeleteAccount .card .title {
  font-size: 26px;
  color: black;
}

#containerDeleteAccount .card .description {
  font-size: 18px;
  max-width: 300px;
}

#containerDeleteAccount .card .action {
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  background: transparent;
  width: 100%;
}

#containerDeleteAccount .card .action:hover {
  background: #c4c4c4;
  transition: linear 0.5s;
}
