.visitants-container {
    height: 100%;
    display:  flex;
    justify-content: center;
    align-items: center;
    background: var(--purple);
}

#meet {
    width: 100%;
    height: 100%;
}